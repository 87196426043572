import { defaultTheme } from "react-admin";

const Theme = {
	...defaultTheme,
	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Arial",
			"sans-serif",
		].join(","),
		fontSize: 14,
	},
};

export default Theme;
