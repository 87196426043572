import * as React from "react";
import { Box, Chip, useMediaQuery, CircularProgress } from "@mui/material";
import {
	List,
	Datagrid,
	ReferenceField,
	EditButton,
	Edit,
	SimpleForm,
    BooleanInput,
	NumberInput,
	TextInput,
	Create,
	required,
	ShowButton,
	DateField,
	SearchInput,
	useTranslate,
	Show,
	SimpleShowLayout,
	DateInput,
	DateTimeInput,
	ImageInput,
	ImageField,
} from "react-admin";
import IconUpload from '@mui/icons-material/Upload'
import jsonExport from 'jsonexport/dist';
import { MyTextField as TextField } from "./react-admin/textField";
import { fetchUtils } from "ra-core";
import { authHeaders } from "../customDataProvider";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ImageDownloadField } from "./react-admin/imageDownloadField";

export const MemberDetailsCreate = () => (
	<Create redirect="edit">
		<SimpleForm>
            <NumberInput
				source="member_id"
				validate={[required()]}
                disabled
			/>
			<TextInput
				source="year"
				validate={[required()]}
			/>
            <DateInput
				source="paid_at"
				validate={[required()]}
			/>
            <TextInput
				source="receipt_num"
				validate={[required()]}
			/>
            <NumberInput
				source="fee_paid"
				validate={[required()]}
			/>
            <TextInput
				source="valid_from_year"
				validate={[required()]}
			/>
            <TextInput
				source="valid_to_year"
				validate={[required()]}
			/>
            <ImageInput
				source="attachment"
				multiple
				labelMultiple="ra.input.file.upload_several"
				maxSize={8000000}
				accept="image/*">
				<ImageField source="src" title="title" />
			</ImageInput>
		</SimpleForm>
	</Create>
);

export const MemberDetailsEdit = () => (
	<Edit redirect="edit">
		<SimpleForm>
        <NumberInput
				source="member_id"
				validate={[required()]}
                disabled
			/>
			<TextInput
				source="year"
				validate={[required()]}
			/>
            <DateInput
				source="paid_at"
				validate={[required()]}
			/>
            <TextInput
				source="receipt_num"
				validate={[required()]}
			/>
            <NumberInput
				source="fee_paid"
				validate={[required()]}
			/>
            <TextInput
				source="valid_from_year"
				validate={[required()]}
			/>
            <TextInput
				source="valid_to_year"
				validate={[required()]}
			/>
            <ImageInput
				source="attachment"
				multiple
				labelMultiple="ra.input.file.upload_several"
				maxSize={8000000}
				accept="image/*">
				<ImageField source="src" title="title" />
			</ImageInput>
			<DateTimeInput disabled source="created_at" fullWidth />
			<DateTimeInput disabled source="updated_at" fullWidth />
			<TextInput disabled source="last_edited_by" fullWidth />
			<TextInput disabled source="id" fullWidth />
		</SimpleForm>
	</Edit>
);