interface ImageDownloadFieldProps {
    images: any[];
}

export const ImageDownloadField = (props: ImageDownloadFieldProps) => (
    <div>
        <ul style={{ display: 'flex', listStyleType: 'none' }}>
            {
                props.images && props.images.map((a: any) => (
                    <li>
                        <a href={a.src} target="_blank"  style={{ margin: '0.25rem' }}>
                            <img src={a.src}  style={{ width: '200px', height: '100px', objectFit: 'contain' }}/><br/>
                            <div style={{ textAlign: 'center' }}>下載</div>
                        </a>
                    </li>
                ))
            }
        </ul>
    </div>
);