import * as React from "react";
import { CircularProgress } from "@mui/material";
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	EditButton,
	Edit,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	TextInput,
	Create,
	required,
	ShowButton,
	DateField,
	SearchInput,
	useTranslate,
	Show,
	SimpleShowLayout,
	DateInput,
	DateTimeInput,
	AutocompleteInput,
	ImageInput,
	ImageField,
	FileField,
	useDataProvider,
	FunctionField,
	WithRecord,
	downloadCSV,
	Exporter,
	usePermissions,
    NumberInput,
    ReferenceArrayField,
    ReferenceManyField,
    BooleanField,
    Button,
    CreateButton,
    ExportButton,
    FilterButton,
    TopToolbar,
    Form,
	FileInput,
	useGetRecordId,
	AddItemButton,
	IconButtonWithTooltip,
} from "react-admin";
import IconUpload from '@mui/icons-material/Upload'
import IconPrint from '@mui/icons-material/Print'
import AddIcon from '@mui/icons-material/AddCircleOutline';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { fetchUtils } from "ra-core";
import { authHeaders } from "../customDataProvider";

export const BlessingList = () => {
	const { permissions } = usePermissions();
	
	return (
		<List>
			<Datagrid isRowSelectable={(_) => permissions !== "30"}>
				<DateField source="started_at" />
				<TextField source="title" />
				<DateField showTime={true} source="created_at" />
				<DateField showTime={true} source="updated_at" />
				<TextField source="last_edited_by" />
				{
					permissions !== "30" &&
					<EditButton />
				}
				<ShowButton />
			</Datagrid>
		</List>
	)
};

export const BlessingCreate = () => (
	<Create>
		<SimpleForm>
			<DateInput source="started_at" validate={[required()]} />
			<TextInput source="title" validate={[required()]} />
		</SimpleForm>
	</Create>
);

export const BlessingEdit = () => (
	<Edit>
		<SimpleForm>
        <DateInput source="started_at" validate={[required()]} />
			<TextInput source="title" validate={[required()]} />
		</SimpleForm>
	</Edit>
);

const ShowActions = () => {
    const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const recordId = useGetRecordId();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

	const handleClickPrint = async () => {
		const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
		const url = `${apiUrl}/blessings/${recordId}/print`;
		const headers = authHeaders();
		const res = await fetch(url, {
			headers
		})

		const blob = await res.blob();

		const fakeLink = document.createElement('a');
		fakeLink.style.display = 'none';
		document.body.appendChild(fakeLink);
		// @ts-ignore
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			// Manage IE11+ & Edge
			// @ts-ignore
			window.navigator.msSaveOrOpenBlob(blob, 'record.pdf');
		} else {
			fakeLink.setAttribute('href', URL.createObjectURL(blob));
			fakeLink.setAttribute('download', `record.pdf`);
			fakeLink.click();
		}
	}

    return (
        <TopToolbar>
            <EditButton/>
            {/* Add your custom actions */}
            <Button
                onClick={handleClickOpen}
                label="匯入"
            >
                <IconUpload/>
            </Button>
			<Button onClick={handleClickPrint} label="打印">
				<IconPrint />
			</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>匯入</DialogTitle>
                <DialogContent>
                    <Form onSubmit={(data) => {
						setLoading(true);
						const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
						const url = `${apiUrl}/blessings/${data.id}/import`;
						const headers = authHeaders();
						const body = new FormData();
						if (!data.file) {
							setLoading(false);
							return
						}
						body.append('file', data.file.rawFile);
                        body.append('type', data.type);
					
						fetchUtils.fetchJson(url, {
							headers,
							method: 'POST',
							body,
						}).then(({ json }) => {
							handleClose();
						}).finally(() => setLoading(false));
					}}>
                        <SelectInput label="resources.amulets.fields.type" source={`type`} choices={[
                            { id: 'personal', name: '個人' },
                            { id: 'family', name: '合家'}
                        ]} />
						<FileInput source="file">
							<FileField source="src" title="title" />
						</FileInput>
						<Button type="submit" label="上傳" disabled={loading}></Button>
                    </Form>
					{loading && (
						<CircularProgress size={25} thickness={2} />
					)}
                </DialogContent>
            </Dialog>
        </TopToolbar>
    );
};

export const BlessingShow = () => (
	<Show actions={<ShowActions/>}>
		<SimpleShowLayout>
            <DateField source="started_at" />
            <TextField source="title" />
            <DateField showTime={true} source="created_at" />
            <DateField showTime={true} source="updated_at" />
            <TextField source="last_edited_by" />
			<TextField source="id" />
            <ReferenceManyField target="blessing_id" reference="amulets">
              <Datagrid isRowSelectable={(_) => false}>
				<TextField source="type" />
                <TextField source="amulet_id" />
                <TextField source="title" />
                <TextField source="subtitle" />
				<TextField source="remarks" />
              </Datagrid>
            </ReferenceManyField>
		</SimpleShowLayout>
	</Show>
);
