import * as React from "react";
import { CircularProgress } from "@mui/material";
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	EditButton,
	Edit,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	TextInput,
	Create,
	required,
	ShowButton,
	DateField,
	SearchInput,
	useTranslate,
	Show,
	SimpleShowLayout,
	DateInput,
	DateTimeInput,
	AutocompleteInput,
	ImageInput,
	ImageField,
	FileField,
	useDataProvider,
	FunctionField,
	WithRecord,
	downloadCSV,
	Exporter,
	usePermissions,
	NumberInput,
	ReferenceArrayField,
	ReferenceManyField,
	BooleanField,
	Button,
	CreateButton,
	ExportButton,
	FilterButton,
	TopToolbar,
	Form,
	FileInput,
	useGetRecordId,
	AddItemButton,
	IconButtonWithTooltip,
	TranslatableFields,
} from "react-admin";
import IconUpload from "@mui/icons-material/Upload";
import IconPrint from "@mui/icons-material/Print";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { fetchUtils } from "ra-core";
import { authHeaders } from "../customDataProvider";

export const CeremonyList = () => {
	const { permissions } = usePermissions();

	return (
		<List>
			<Datagrid isRowSelectable={(_) => permissions !== "30"}>
				<DateField source="started_at" />
				<TextField source="title" />
				<TextField source="small_tablet_total" />
				<TextField source="small_tablet_order" />
				<TextField source="small_tablet_paid" />
				<TextField source="large_tablet_total" />
				<TextField source="large_tablet_order" />
				<TextField source="large_tablet_paid" />
				<TextField source="extra_large_tablet_total" />
				<TextField source="extra_large_tablet_order" />
				<TextField source="extra_large_tablet_paid" />
				<TextField source="normal_tablet_total" />
				<TextField source="normal_tablet_order" />
				<TextField source="normal_tablet_paid" />
				{/* <DateField showTime={true} source="created_at" />
				<DateField showTime={true} source="updated_at" />
				<TextField source="last_edited_by" /> */}
				{permissions !== "30" && <EditButton />}
				<ShowButton />
			</Datagrid>
		</List>
	);
};

export const CeremonyCreate = () => (
	<Create>
		<SimpleForm>
			<DateInput source="started_at" validate={[required()]} />
			<TextInput source="title" validate={[required()]} />
			<NumberInput source="small_tablet_total" validate={[required()]} />
			<NumberInput source="small_tablet_order" validate={[required()]} />
			<NumberInput source="small_tablet_paid" validate={[required()]} />
			<NumberInput source="large_tablet_total" validate={[required()]} />
			<NumberInput source="large_tablet_order" validate={[required()]} />
			<NumberInput source="large_tablet_paid" validate={[required()]} />
			<NumberInput
				source="extra_large_tablet_total"
				validate={[required()]}
			/>
			<NumberInput
				source="extra_large_tablet_order"
				validate={[required()]}
			/>
			<NumberInput
				source="extra_large_tablet_paid"
				validate={[required()]}
			/>
			<NumberInput source="normal_tablet_total" validate={[required()]} />
			<NumberInput source="normal_tablet_order" validate={[required()]} />
			<NumberInput source="normal_tablet_paid" validate={[required()]} />
		</SimpleForm>
	</Create>
);

export const CeremonyEdit = () => (
	<Edit>
		<SimpleForm>
			<DateInput source="started_at" validate={[required()]} />
			<TextInput source="title" validate={[required()]} />
			<NumberInput source="small_tablet_total" validate={[required()]} />
			<NumberInput source="small_tablet_order" validate={[required()]} />
			<NumberInput source="small_tablet_paid" validate={[required()]} />
			<NumberInput source="large_tablet_total" validate={[required()]} />
			<NumberInput source="large_tablet_order" validate={[required()]} />
			<NumberInput source="large_tablet_paid" validate={[required()]} />
			<NumberInput
				source="extra_large_tablet_total"
				validate={[required()]}
			/>
			<NumberInput
				source="extra_large_tablet_order"
				validate={[required()]}
			/>
			<NumberInput
				source="extra_large_tablet_paid"
				validate={[required()]}
			/>
			<NumberInput source="normal_tablet_total" validate={[required()]} />
			<NumberInput source="normal_tablet_order" validate={[required()]} />
			<NumberInput source="normal_tablet_paid" validate={[required()]} />
		</SimpleForm>
	</Edit>
);

const ShowActions = () => {
	const [open, setOpen] = React.useState(false);
	const [normalOpen, setNormalOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const [count, setCount] = React.useState(1);

	const recordId = useGetRecordId();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickPrint = async (size: string) => {
		const apiUrl = process.env.REACT_APP_API_BASE_URL || "";
		const url = `${apiUrl}/ceremonies/${recordId}/print?size=${size}`;
		const headers = authHeaders();
		const res = await fetch(url, {
			headers,
		});

		const blob = await res.blob();

		const fakeLink = document.createElement("a");
		fakeLink.style.display = "none";
		document.body.appendChild(fakeLink);
		// @ts-ignore
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			// Manage IE11+ & Edge
			// @ts-ignore
			window.navigator.msSaveOrOpenBlob(blob, "record.pdf");
		} else {
			fakeLink.setAttribute("href", URL.createObjectURL(blob));
			fakeLink.setAttribute("download", `record.pdf`);
			fakeLink.click();
		}
	};

	return (
		<TopToolbar>
			<EditButton />
			{/* Add your custom actions */}
			<Button onClick={handleClickOpen} label="匯入小龍/大龍/特大龍">
				<IconUpload />
			</Button>
			<Button onClick={() => setNormalOpen(true)} label="匯入普通龍">
				<IconUpload />
			</Button>
			<Button onClick={() => handleClickPrint("small")} label="打印小龍">
				<IconPrint />
			</Button>
			<Button onClick={() => handleClickPrint("large")} label="打印大龍">
				<IconPrint />
			</Button>
			<Button
				onClick={() => handleClickPrint("extraLarge")}
				label="打印特大龍">
				<IconPrint />
			</Button>
			<Button
				onClick={() => handleClickPrint("normal")}
				label="打印普通龍">
				<IconPrint />
			</Button>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>匯入小龍/大龍/特大龍</DialogTitle>
				<DialogContent>
					<IconButtonWithTooltip
						label="ra.action.add"
						size="small"
						onClick={() => setCount(count + 1)}
						color="primary">
						<AddIcon fontSize="small" />
					</IconButtonWithTooltip>
					<Form
						onSubmit={(data) => {
							setLoading(true);
							const apiUrl =
								process.env.REACT_APP_API_BASE_URL || "";
							const url = `${apiUrl}/ceremonies/${data.id}/import`;
							const headers = authHeaders();
							const body = new FormData();
							if (!data.file) {
								setLoading(false);
								return;
							}
							body.append("file", data.file.rawFile);
							body.append("sizes", data.size.join(","));
							body.append("sheets", data.sheet.join(","));

							fetchUtils
								.fetchJson(url, {
									headers,
									method: "POST",
									body,
								})
								.then(({ json }) => {
									handleClose();
								})
								.finally(() => setLoading(false));
						}}>
						{Array(count)
							.fill(0)
							.map((_, i) => (
								<Box>
									<SelectInput
										label="resources.ceremony-tablets.fields.size"
										source={`size[${i}]`}
										choices={[
											{ id: "small", name: "小龍" },
											{ id: "large", name: "大龍" },
											{
												id: "extraLarge",
												name: "特大龍",
											},
										]}
									/>
									<NumberInput
										label="resources.ceremony-tablets.fields.sheet"
										source={`sheet[${i}]`}
									/>
								</Box>
							))}
						<FileInput source="file">
							<FileField source="src" title="title" />
						</FileInput>
						<Button
							type="submit"
							label="上傳"
							disabled={loading}></Button>
					</Form>
					{loading && <CircularProgress size={25} thickness={2} />}
				</DialogContent>
			</Dialog>
			<Dialog open={normalOpen} onClose={() => setNormalOpen(false)}>
				<DialogTitle>匯入普通龍</DialogTitle>
				<DialogContent>
					<IconButtonWithTooltip
						label="ra.action.add"
						size="small"
						onClick={() => setCount(count + 1)}
						color="primary">
						<AddIcon fontSize="small" />
					</IconButtonWithTooltip>
					<Form
						onSubmit={(data) => {
							setLoading(true);
							const apiUrl =
								process.env.REACT_APP_API_BASE_URL || "";
							const url = `${apiUrl}/ceremonies/${data.id}/import`;
							const headers = authHeaders();
							const body = new FormData();
							if (!data.file) {
								setLoading(false);
								return;
							}
							body.append("file", data.file.rawFile);
							body.append(
								"sizes",
								new Array(data.sheet.length)
									.fill("normal")
									.join(",")
							);
							body.append("sheets", data.sheet.join(","));

							fetchUtils
								.fetchJson(url, {
									headers,
									method: "POST",
									body,
								})
								.then(({ json }) => {
									setNormalOpen(false);
								})
								.finally(() => setLoading(false));
						}}>
						{Array(count)
							.fill(0)
							.map((_, i) => (
								<Box>
									<NumberInput
										label="resources.ceremony-tablets.fields.sheet"
										source={`sheet[${i}]`}
									/>
								</Box>
							))}
						<FileInput source="file">
							<FileField source="src" title="title" />
						</FileInput>
						<Button
							type="submit"
							label="上傳"
							disabled={loading}></Button>
					</Form>
					{loading && <CircularProgress size={25} thickness={2} />}
				</DialogContent>
			</Dialog>
		</TopToolbar>
	);
};

export const CeremonyShow = () => (
	<Show actions={<ShowActions />}>
		<SimpleShowLayout>
			<DateField source="started_at" />
			<TextField source="title" />
			<TextField source="small_tablet_total" />
			<TextField source="small_tablet_order" />
			<TextField source="small_tablet_paid" />
			<TextField source="large_tablet_total" />
			<TextField source="large_tablet_order" />
			<TextField source="large_tablet_paid" />
			<TextField source="extra_large_tablet_total" />
			<TextField source="extra_large_tablet_order" />
			<TextField source="extra_large_tablet_paid" />
			<TextField source="normal_tablet_total" />
			<TextField source="normal_tablet_order" />
			<TextField source="normal_tablet_paid" />
			<DateField showTime={true} source="created_at" />
			<DateField showTime={true} source="updated_at" />
			<TextField source="last_edited_by" />
			<TextField source="id" />
			<ReferenceManyField
				target="ceremony_id"
				reference="ceremony-tablets">
				<Datagrid isRowSelectable={(_) => false}>
					<FunctionField
						source="size"
						label="尺寸"
						render={(record: any) =>
							record.size == "small"
								? "小龍"
								: record.size == "normal"
								? "普通龍"
								: "大龍"
						}
					/>
					<TextField source="tablet_id" />
					<TextField source="content" />
					<TextField source="address" />
					<TextField source="remarks" />
					<BooleanField source="paid" />
					<BooleanField source="additional_text" />
				</Datagrid>
			</ReferenceManyField>
		</SimpleShowLayout>
	</Show>
);
