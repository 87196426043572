import {
	List,
	Datagrid,
	DateField,
	TextField,
	EditButton,
	Edit,
	SimpleForm,
	TextInput,
	Create,
	Show,
	ShowButton,
	required,
	DateTimeInput,
	SimpleShowLayout,
	NumberInput,
	downloadCSV,
	Exporter,
	usePermissions,
} from "react-admin";

import jsonExport from 'jsonexport/dist';

const exporter: Exporter = (records) => {
    const data = records.map((record: any) => ({
		...record,
	}));
	return jsonExport(data, {
		rename: ['系統編號', '區域', '龕位共有', '己售出', '可以售賣', '建立時間', '最後修改日期', '最後修改者'],
	}, (err, csv) => {
		downloadCSV(csv, 'rooms');
	});
};

export const RoomList = () => {
	const { permissions } = usePermissions();
	
	return (
		<List exporter={exporter}>
			<Datagrid rowClick="edit" isRowSelectable={(_) => permissions !== "30"}>
				{/* <TextField source="id" /> */}
				<TextField source="name" />
				<TextField source="tablet_total" />
				<TextField source="tablet_sold" />
				<TextField source="tablet_available" />
				<DateField showTime={true} source="created_at" />
				<DateField showTime={true} source="updated_at" />
				<TextField source="last_edited_by" />
				{/* <TextField source="contact_person" /> */}
				{/* <TextField source="title" />                                     */}
				{/* <DateField source="created_at" /> */}
				{/* <TextField source="category" /> */}

				{
					permissions !== "30" &&
					<EditButton />
				}
				<ShowButton />
			</Datagrid>
		</List>
	)
};

export const RoomCreate = () => (
	<Create>
		<SimpleForm>
			<TextInput source="name" validate={[required()]} fullWidth />
			<NumberInput
				source="tablet_total"
				validate={[required()]}
				fullWidth
			/>
		</SimpleForm>
	</Create>
);

export const RoomEdit = () => (
	<Edit>
		<SimpleForm>
			<TextInput source="name" validate={[required()]} fullWidth />
			<NumberInput
				source="tablet_total"
				validate={[required()]}
				fullWidth
			/>
			<TextInput
				source="tablet_sold"
				disabled
				validate={[required()]}
				fullWidth
			/>
			<TextInput
				source="tablet_available"
				disabled
				validate={[required()]}
				fullWidth
			/>
			<DateTimeInput disabled source="created_at" fullWidth />
			<DateTimeInput disabled source="updated_at" fullWidth />
			<TextInput disabled source="last_edited_by" fullWidth />
			<TextInput disabled source="id" fullWidth />
		</SimpleForm>
	</Edit>
);

export const RoomShow = () => (
	<Show>
		<SimpleShowLayout>
			<TextField source="name" />
			<TextField source="tablet_total" />
			<TextField source="tablet_sold" />
			<TextField source="tablet_available" />
			<DateField showTime={true} source="created_at" />
			<DateField showTime={true} source="updated_at" />
			<TextField source="last_edited_by" />
			<TextField source="id" />
		</SimpleShowLayout>
	</Show>
);
