import * as React from "react";
import { Box, Chip, useMediaQuery, CircularProgress } from "@mui/material";
import {
	List,
	Datagrid,
	ReferenceField,
	EditButton,
	Edit,
	SimpleForm,
    BooleanInput,
	NumberInput,
	TextInput,
	Create,
	required,
	ShowButton,
	DateField,
	SearchInput,
	useTranslate,
	Show,
	SimpleShowLayout,
	DateInput,
	DateTimeInput,
	downloadCSV,
	Exporter,
	ReferenceManyField,
	ReferenceArrayField,
	usePermissions,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    useGetRecordId,
    Form,
    FileInput,
    FileField,
    BooleanField,
    WithRecord,
    Button as RButton,
    ImageField,
    ImageInput,
} from "react-admin";
import { Button } from '@mui/material';
import IconUpload from '@mui/icons-material/Upload'
import jsonExport from 'jsonexport/dist';
import { MyTextField as TextField } from "./react-admin/textField";
import { fetchUtils } from "ra-core";
import { authHeaders } from "../customDataProvider";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ImageDownloadField } from "./react-admin/imageDownloadField";
import { Link } from 'react-router-dom';
import ContentAdd from '@mui/icons-material/Add';
import IconPrint from '@mui/icons-material/Print'

const QuickFilter = ({
	label,
	source,
	defaultValue,
}: {
	label: string;
	source: string;
	defaultValue: string | undefined;
}) => {
	const translate = useTranslate();
	return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

const exporter: Exporter = (records, fetchRelatedRecords, dataProvider) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
	const url = `${apiUrl}/members/export`;
	const headers = authHeaders();

	fetchUtils.fetchJson(url, {
		headers,
		method: 'POST'
	}).then(({ json }) => {
		return jsonExport(json, {
			rename: ['系統編號', '編號', '名稱', '會籍有效期至', '入會日期', '會費', '收據號碼', '會籍', '豁免年費', '可接洽', '地址', '電話', '身份證號碼', '備註/其他', '繳交會費狀況', '建立時間', '最後修改日期', '最後修改者'],
		}, (err, csv) => {
			downloadCSV(csv, 'members');
		});
	});
};

const memberFilter = [
	<TextInput source="fullname" defaultValue="" />,
	<TextInput source="contact_addr" defaultValue="" />,
	<TextInput source="phone" defaultValue="" />,
];

const ListActions = () => {
    const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

	const handleClickPrint = async () => {
		const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
		const url = `${apiUrl}/members/print`;
		const headers = authHeaders();
		const res = await fetch(url, {
			headers
		})

		const blob = await res.blob();

		const fakeLink = document.createElement('a');
		fakeLink.style.display = 'none';
		document.body.appendChild(fakeLink);
		// @ts-ignore
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			// Manage IE11+ & Edge
			// @ts-ignore
			window.navigator.msSaveOrOpenBlob(blob, 'record.pdf');
		} else {
			fakeLink.setAttribute('href', URL.createObjectURL(blob));
			fakeLink.setAttribute('download', `record.pdf`);
			fakeLink.click();
		}
	}

    return (
        <TopToolbar>
            <FilterButton/>
            <CreateButton/>
            <RButton
                onClick={handleClickOpen}
                label="匯入"
            >
                <IconUpload/>
            </RButton>
            <ExportButton/>
			<RButton onClick={handleClickPrint} label="打印地址">
				<IconPrint />
			</RButton>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>匯入</DialogTitle>
                <DialogContent>
                    <Form onSubmit={(data) => {
						setLoading(true);
						const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
						const url = `${apiUrl}/members/import`;
						const headers = authHeaders();
						const body = new FormData();
						if (!data.file) {
							setLoading(false);
							return
						}
						body.append('file', data.file.rawFile);
					
						fetchUtils.fetchJson(url, {
							headers,
							method: 'POST',
							body,
						}).then(() => {
							handleClose();
						}).finally(() => setLoading(false));
					}}>
						<FileInput source="file">
							<FileField source="src" title="title" />
						</FileInput>
						<RButton type="submit" label="上傳" disabled={loading}></RButton>
                    </Form>
					{loading && (
						<CircularProgress size={25} thickness={2} />
					)}
                </DialogContent>
            </Dialog>
        </TopToolbar>
    )
};

export const MemberList = () => {
	const { permissions } = usePermissions();
	
	return (
		<List filters={memberFilter} actions={ListActions()} exporter={exporter}>
			<Datagrid isRowSelectable={(_) => permissions !== "30"}>
                <TextField source="member_id" />
                <TextField source="fullname" />
                <TextField source="expiry_year" />
                <TextField source="receipt_num" />
                <BooleanField source="valid" />
                <BooleanField source="paid" />
                <BooleanField source="can_contact" />
				<TextField source="contact_addr" />
				<TextField source="phone" />
                <TextField source="remarks" />
				<DateField showTime={true} source="updated_at" />
				<TextField source="last_edited_by" />
				{
					permissions !== "30" &&
					<EditButton />
				}
				<ShowButton />
			</Datagrid>
		</List>
	)
};

export const MemberCreate = () => (
	<Create>
		<SimpleForm>
            <NumberInput
				source="member_id"
				validate={[required()]}
			/>
			<TextInput source="fullname" validate={[required()]} fullWidth />
            <DateInput
				source="registered_at"
			/>
            <NumberInput
				source="membership_fee"
				validate={[required()]}
			/>
            <TextInput
				source="receipt_num"
				validate={[required()]}
			/>
            <BooleanInput
				source="valid"
				validate={[required()]}
			/>
            <BooleanInput
				source="fee_excluded"
				validate={[required()]}
			/>
            <BooleanInput
				source="can_contact"
				validate={[required()]}
			/>
            <TextInput
				source="contact_addr"
				validate={[required()]}
				fullWidth
			/>
			<TextInput source="phone" validate={[required()]} />
            <TextInput source="hkid" />
            <TextInput source="remarks" fullWidth />
		</SimpleForm>
	</Create>
);

export const MemberEdit = () => (
	<Edit>
		<SimpleForm>
        <NumberInput
				source="member_id"
				validate={[required()]}
			/>
			<TextInput source="fullname" validate={[required()]} fullWidth />
            <DateInput
				source="registered_at"
			/>
            <NumberInput
				source="membership_fee"
				validate={[required()]}
			/>
            <TextInput
				source="receipt_num"
				validate={[required()]}
			/>
            <BooleanInput
				source="valid"
				validate={[required()]}
			/>
            <BooleanInput
				source="fee_excluded"
				validate={[required()]}
			/>
            <BooleanInput
				source="can_contact"
				validate={[required()]}
			/>
            <TextInput
				source="contact_addr"
				validate={[required()]}
				fullWidth
			/>
			<TextInput source="phone" validate={[required()]} />
            <TextInput source="hkid" />
            <TextInput source="remarks" fullWidth />
			<DateTimeInput disabled source="created_at" fullWidth />
			<DateTimeInput disabled source="updated_at" fullWidth />
			<TextInput disabled source="last_edited_by" fullWidth />
			<TextInput disabled source="id" fullWidth />
		</SimpleForm>
	</Edit>
);

export const MemberShow = () => {
    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="member_id" />
                <TextField source="fullname" />
                <TextField source="expiry_year" />
                <DateField source="registered_at" />
                <TextField source="membership_fee" />
                <TextField source="receipt_num" />
                <BooleanField source="valid" />
                <BooleanField source="fee_excluded" />
                <BooleanField source="can_contact" />
                <TextField source="contact_addr" />
                <TextField source="phone" />
                <TextField source="hkid" />
                <TextField source="remarks" />
                <DateField showTime={true} source="created_at" />
                <DateField showTime={true} source="updated_at" />
                <TextField source="last_edited_by" />
                <TextField source="id" />
                <ReferenceManyField label="繳交會費詳細" source="member_id" target="member_id" reference="member-details">
                    <WithRecord label="附件" render={record => (
                        <Button
                            component={Link}
                            to={{
                                pathname: '/member-details/create',
                            }}
                            state={{ record: { member_id: record.member_id } }}
                        >
                            <ContentAdd />繳交會費詳細
                        </Button>)
                    }/>
                    <Datagrid>
                        <TextField source="year" />
                        <DateField source="paid_at" />
                        <TextField source="receipt_num" />
                        <TextField source="fee_paid" />
                        <TextField source="valid_from_year" />
                        <TextField source="valid_to_year" />
                        <WithRecord label="附件" render={record => (
                            <ImageDownloadField images={record.attachment} />
                        )} />
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    )
};
