import { AuthProvider } from "react-admin";

const authProvider: AuthProvider = {
	login: ({ email, password }) => {
		const request = new Request(`${process.env.REACT_APP_API_BASE_URL}/auth`, {
			method: "POST",
			body: JSON.stringify({ email, password }),
			headers: new Headers({ "Content-Type": "application/json" }),
		});
		return fetch(request)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then(({ token }) => {
				// store the token in local storage
				localStorage.setItem("token", token);
				return Promise.resolve();
			})
			.catch(() => {
				throw new Error("Network error");
			});
	},
	logout: () => {
		localStorage.removeItem("token");
		localStorage.removeItem('permissions');
		return Promise.resolve();
	},
	checkError: () => Promise.resolve(),
	checkAuth: () =>
		localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
	getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    },
	getIdentity: () => {
		const token = localStorage.getItem("token");
		const request = new Request(`${process.env.REACT_APP_API_BASE_URL}/auth/me`, {
			method: "POST",
			headers: new Headers({ "Content-Type": "application/json", Authorization: `${token}` }),
		});
		return fetch(request)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then(({ me }) => {
				localStorage.setItem('permissions', me.role);
				return Promise.resolve({
					id: me.email,
					fullName: me.full_name,
				});
			})
			.catch(() => {
				throw new Error("Network error");
			});
	}
};

export default authProvider;
