import * as React from "react";
import { Box, Chip, useMediaQuery } from "@mui/material";
import {
	List,
	Datagrid,
	ReferenceField,
	EditButton,
	Edit,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	TextInput,
	Create,
	required,
	ShowButton,
	DateField,
	SearchInput,
	useTranslate,
	Show,
	SimpleShowLayout,
	DateInput,
	DateTimeInput,
	downloadCSV,
	Exporter,
	ReferenceManyField,
	ReferenceArrayField,
	usePermissions,
} from "react-admin";
import jsonExport from 'jsonexport/dist';
import { MyTextField as TextField } from "./react-admin/textField";
import { fetchUtils } from "ra-core";
import { authHeaders } from "../customDataProvider";

const QuickFilter = ({
	label,
	source,
	defaultValue,
}: {
	label: string;
	source: string;
	defaultValue: string | undefined;
}) => {
	const translate = useTranslate();
	return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

const exporter: Exporter = (records, fetchRelatedRecords, dataProvider) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
	const url = `${apiUrl}/contact-persons/export`;
	const headers = authHeaders();

	fetchUtils.fetchJson(url, {
		headers,
		method: 'POST'
	}).then(({ json }) => {
		return jsonExport(json, {
			rename: ['系統編號', '名稱', '地址', '電話', '建立時間', '最後修改日期', '最後修改者'],
		}, (err, csv) => {
			downloadCSV(csv, 'contact-persons');
		});
	});
};

const nicheFilter = [
	// <SearchInput source="q" alwaysOn />,
	<TextInput source="fullname" defaultValue="" />,
	<TextInput source="contact_addr" defaultValue="" />,
	<TextInput source="phone" defaultValue="" />,
	// <QuickFilter
	// 	label="resources.niches.fields.ancestor_fullname"
	// 	source="ancestor_fullname"
	// 	defaultValue=""
	// />,
];

export const ContactPersonList = () => {
	const { permissions } = usePermissions();
	
	return (
		<List filters={nicheFilter} exporter={exporter}>
			<Datagrid isRowSelectable={(_) => permissions !== "30"}>
				<TextField source="fullname" />
				<TextField source="contact_addr" />
				<TextField source="phone" />
				<DateField showTime={true} source="created_at" />
				<DateField showTime={true} source="updated_at" />
				<TextField source="last_edited_by" />
				{
					permissions !== "30" &&
					<EditButton />
				}
				<ShowButton />
			</Datagrid>
		</List>
	)
};

export const ContactPersonCreate = () => (
	<Create>
		<SimpleForm>
			<TextInput source="fullname" validate={[required()]} fullWidth />
			<TextInput
				source="contact_addr"
				validate={[required()]}
				fullWidth
			/>
			<TextInput source="phone" validate={[required()]} fullWidth />
		</SimpleForm>
	</Create>
);

export const ContactPersonEdit = () => (
	<Edit>
		<SimpleForm>
			<TextInput source="fullname" validate={[required()]} fullWidth />
			<TextInput
				source="contact_addr"
				validate={[required()]}
				fullWidth
			/>
			<TextInput source="phone" validate={[required()]} fullWidth />
			<DateTimeInput disabled source="created_at" fullWidth />
			<DateTimeInput disabled source="updated_at" fullWidth />
			<TextInput disabled source="last_edited_by" fullWidth />
			<TextInput disabled source="id" fullWidth />
		</SimpleForm>
	</Edit>
);

export const ContactPersonShow = () => (
	<Show>
		<SimpleShowLayout>
			{/* <TextField source="id" /> */}
			<TextField source="fullname" />
			<TextField source="contact_addr" />
			<TextField source="phone" />
			<DateField showTime={true} source="created_at" />
			<DateField showTime={true} source="updated_at" />
			<TextField source="last_edited_by" />
			<TextField source="id" />
			<ReferenceArrayField label="靈位" source="niches" reference="niches">
              <Datagrid isRowSelectable={(_) => false}>
				<ReferenceField
					label="resources.sections.name"
					source="section_id"
					reference="sections">
					<TextField source="name" label="section" />
				</ReferenceField>
				<DateField source="sold_at" />
				<TextField source="receipt_num" />
				<TextField source="niche_id" />
				<TextField source="tablet_id" />
				<TextField source="ancestor_surname" />
				<TextField source="ancestor_fullname" />
				<TextField source="ancestor_occupied" />
				<TextField source="remarks" />
              </Datagrid>
            </ReferenceArrayField>
			<ReferenceArrayField label="長生位" source="memorial_tablets" reference="memorial-tablets">
              <Datagrid isRowSelectable={(_) => false}>
				<ReferenceField
					label="resources.rooms.name"
					source="room_id"
					reference="rooms">
					<TextField source="name" label="room" />
				</ReferenceField>
				<DateField source="sold_at" />
				<TextField source="receipt_num" />
				<TextField source="tablet_id" />
				<TextField source="ancestor_surname" />
				<TextField source="ancestor_fullname" />
				<TextField source="ancestor_occupied" />
				<TextField source="remarks" />
              </Datagrid>
            </ReferenceArrayField>
		</SimpleShowLayout>
	</Show>
);
