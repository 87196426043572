import * as React from 'react';
import { Layout, AppBar, Logout, UserMenu, useUserMenu } from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';

export const MyLayout = props => (
    <>
        <Layout {...props} appBar={MyAppBar} />
        <div style={{
    position: 'fixed', right: 0, bottom: 0, left: 0, zIndex: 100,
    padding: 6,
    paddingLeft: 15,
    backgroundColor: '#efefef',
    textAlign: 'left',
  }}><strong>版權所有 © 香港天德聖教忠和精舍有限公司</strong></div>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </>
);

const ConfigurationMenu = React.forwardRef((props, ref) => {
    return (
        <MenuItem
            ref={ref}
            component={Link}
            // It's important to pass the props to allow MUI to manage the keyboard navigation
            {...props}
            to="/change-password"
        >
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText>
               更改密碼
            </ListItemText>
        </MenuItem>
    );
});

const MyUserMenu = props => (
    <UserMenu {...props}>
        <ConfigurationMenu />
        <Logout />
    </UserMenu>
);

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />;