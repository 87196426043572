/* eslint-disable import/no-anonymous-default-export */
import { stringify } from "query-string";
import { fetchUtils, DataProvider } from "ra-core";

const convertFileToBase64 = (file: any) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;

		reader.readAsDataURL(file.rawFile);
	});

export const authHeaders = () => {
	const h = new Headers({ Accept: "application/json" });
	const token = localStorage.getItem("token");
	if (token) {
		return new Headers({ Accept: "application/json", Authorization: `${token}` });
	}

	return h
}

export default (
	apiUrl: string,
	httpClient = fetchUtils.fetchJson,
	countHeader: string = "Content-Range",
): DataProvider => ({
	getList: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;

		const rangeStart = (page - 1) * perPage;
		const rangeEnd = page * perPage - 1;

		const query = {
			sort: JSON.stringify([field, order]),
			range: JSON.stringify([rangeStart, rangeEnd]),
			filter: JSON.stringify(params.filter),
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;
		const headers = authHeaders();
		if (countHeader === "Content-Range") {
			headers.append(
				'Range', `${resource}=${rangeStart}-${rangeEnd}`,
			)
		}
		const options =
			countHeader === "Content-Range"
				? {
						// Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
						headers,
				  }
				: {};

		return httpClient(url, options).then(({ headers, json }) => {
			if (!headers.has(countHeader)) {
				throw new Error(
					`The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
				);
			}
			return {
				data: json,
				total:
					countHeader === "Content-Range"
						? parseInt(
								headers
									?.get("content-range")
									?.split("/")
									.pop() as string,
								10
						  )
						: parseInt(
								headers.get(countHeader.toLowerCase()) as string
						  ),
			};
		});
	},

	getOne: (resource, params) =>
		httpClient(`${apiUrl}/${resource}/${params.id}`, {
			headers: authHeaders(),
		}).then(({ json }) => ({
			data: json,
		})),

	getMany: (resource, params) => {
		const query = {
			filter: JSON.stringify({ id: params.ids }),
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;
		return httpClient(url, {
			headers: authHeaders(),
		}).then(({ json }) => ({ data: json }));
	},

	getManyReference: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;

		const rangeStart = (page - 1) * perPage;
		const rangeEnd = page * perPage - 1;

		const query = {
			sort: JSON.stringify([field, order]),
			range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
			filter: JSON.stringify({
				...params.filter,
				[params.target]: params.id,
			}),
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;
		const headers = authHeaders();
		if (countHeader === "Content-Range") {
			headers.append(
				'Range', `${resource}=${rangeStart}-${rangeEnd}`,
			)
		}
		const options =
			countHeader === "Content-Range"
				? {
						// Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
						headers,
				  }
				: {};

		return httpClient(url, options).then(({ headers, json }) => {
			if (!headers.has(countHeader)) {
				throw new Error(
					`The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
				);
			}
			return {
				data: json,
				total:
					countHeader === "Content-Range"
						? parseInt(
								headers
									?.get("content-range")
									?.split("/")
									.pop() as string,
								10
						  )
						: parseInt(
								headers.get(countHeader.toLowerCase()) as string
						  ),
			};
		});
	},

	update: (resource, params) => {
		if (!params.data.attachment) {
			return httpClient(`${apiUrl}/${resource}/${params.id}`, {
				method: "PUT",
				body: JSON.stringify(params.data),
				headers: authHeaders(),
			}).then(({ json }) => ({ data: json }));
		}

		const newPictures = (params.data.attachment || []).filter(
            (p: any) => p.rawFile instanceof File
        );
		const oldAttachments = (params.data.attachment || []).filter(
			(p: any) => p.src && !p.rawFile
		).map((p: any) => p.src);

		const ids: any = {}

		if (resource === 'niches') {
			ids['niche_id'] = parseInt(params.data.niche_id)
		}

		return Promise.all(newPictures.map(convertFileToBase64))
		.then(base64Pictures =>
			base64Pictures.map((picture64, index) => ({
				src: picture64.substring(picture64.indexOf(',') + 1),
				title: newPictures[index].title,
			}))
		).then(transformedNewPictures => 
			httpClient(`${apiUrl}/${resource}/${params.id}`, {
				method: "PUT",
				body: JSON.stringify({
					...params.data,
					attachment: transformedNewPictures.map(p => p.src),
					attachment_name: transformedNewPictures.map(p => p.title),
					old_attachment: oldAttachments,
					...ids,
				}),
				headers: authHeaders(),
			})).then(({ json }) => ({ data: json }));
	},
	// simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
	updateMany: (resource, params) =>
		Promise.all(
			params.ids.map((id) =>
				httpClient(`${apiUrl}/${resource}/${id}`, {
					headers: authHeaders(),
					method: "PUT",
					body: JSON.stringify(params.data),
				})
			)
		).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

	create: (resource, params) => {
		if (!params.data.attachment) {
			return httpClient(`${apiUrl}/${resource}`, {
				method: "POST",
				body: JSON.stringify(params.data),
				headers: authHeaders(),
			}).then(({ json }) => ({ data: json }));
		}

		const newPictures = (params.data.attachment || []).filter(
            (p: any) => p.rawFile instanceof File
        );

		const ids: any = {}

		if (resource === 'niches') {
			ids['niche_id'] = parseInt(params.data.niche_id)
		}

		return Promise.all(newPictures.map(convertFileToBase64))
		.then(base64Pictures =>
			base64Pictures.map((picture64, index) => ({
				src: picture64.substring(picture64.indexOf(',') + 1),
				title: newPictures[index].title,
			}))
		).then(transformedNewPictures => 
			httpClient(`${apiUrl}/${resource}`, {
			method: "POST",
			body: JSON.stringify({
				...params.data,
				...ids,
				attachment: transformedNewPictures.map(p => p.src),
				attachment_name: transformedNewPictures.map(p => p.title),
			}),
			headers: authHeaders(),
		})).then(({ json }) => ({ data: json }));
	},
	delete: (resource, params) =>
		httpClient(`${apiUrl}/${resource}/${params.id}`, {
			method: "DELETE",
			headers: authHeaders(),
		}).then(({ json }) => ({ data: json })),

	// simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
	deleteMany: (resource, params) =>
		Promise.all(
			params.ids.map((id) =>
				httpClient(`${apiUrl}/${resource}/${id}`, {
					method: "DELETE",
					headers: authHeaders(),
				})
			)
		).then((responses) => ({
			data: responses.map(({ json }) => json.id),
		})),
});
